import { capitalize } from "lodash";
import { useContext, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import UpdateLocation from "../../pages/manage/vehicles/components/UpdateLocation";
import { FiEdit } from "react-icons/fi";
import UpdateSource from "./UpdateSource";
import SpecCheck from "./SpecCheck";
import { GoDotFill } from "react-icons/go";
import { vehicleStatusColors } from "../../constants/colors";
import UpdateStatus from "../../pages/manage/vehicles/components/UpdateStatus";
import { AuthContext } from "../../context/AuthContext";
import formatId from "../../utils/formatId";

export default function FeatureHeader({ vehicle, editable }: any) {
  const { auth } = useContext(AuthContext);
  const [locationModal, setLocationModal] = useState<boolean>(false);
  const [sourceModal, setSourceModal] = useState<boolean>(false);
  const [statusModal, setStatusModal] = useState<boolean>(false);
  const allAccess = [3, 4, 9];
  const oneAccess = [4, 9];
  return (
    <div className="flex justify-between">
      <div className="">
        <div className="text-3xl flex">
          <span className="uppercase">{vehicle?.model?.name}</span>
          <span className="mx-2" style={{ fontSize: 20 }}>
            <div className="flex items-center">
              [
              <div className="flex items-center mr-1">
                <span>
                  <GoDotFill color={vehicleStatusColors[vehicle?.status]} />
                </span>
                {capitalize(vehicle?.status)}

                {allAccess?.includes(auth?.accessLevel) && (
                  <div className="">
                    {editable && (
                      <button
                        onClick={() => setStatusModal(true)}
                        className="ml-2"
                      >
                        <FiEdit size={16} />
                      </button>
                    )}
                  </div>
                )}
              </div>
              ]
            </div>
          </span>
        </div>
        <div className="flex items-center text-lg">
          <div className="flex items-center mr-2">
            <span className="mr-1">CHASSIS No: {vehicle?.chassis}</span>
          </div>
          <div className="w-1 h-1 bg-black"></div>

          <div className="text-gray-500 text-xs px-2" style={{ fontSize: 12 }}>
            STOCK ID: {vehicle?.stockId ? formatId("S", vehicle?.stockId) : "-"}
          </div>
        </div>
      </div>
      <div className="flex flex-col  items-end">
        <SpecCheck vehicle={vehicle} chassis={vehicle?.chassis} />
        <div className="flex items-center p-2 -mt-3">
          <div className="flex items-center">
            <div className="ml-1">
              {vehicle?.source?.name ? vehicle?.source?.name : " - "}
            </div>
          </div>
          {oneAccess?.includes(auth?.accessLevel) && (
            <div className="ml-2">
              {editable && (
                <button
                  onClick={() => setSourceModal(true)}
                  className="hover:text-automark-dark"
                >
                  <BsChevronDown />
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      <UpdateLocation
        isVisible={locationModal}
        setVisible={setLocationModal}
        vehicle={vehicle}
        chassis={vehicle?.chassis}
      />

      <UpdateStatus
        isVisible={statusModal}
        setVisible={setStatusModal}
        vehicle={vehicle}
        chassis={vehicle?.chassis}
      />

      <UpdateSource
        isVisible={sourceModal}
        setVisible={setSourceModal}
        vehicle={vehicle}
        chassis={vehicle?.chassis}
      />
    </div>
  );
}
