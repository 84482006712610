import mobility from "../../assets/images/mobility.jpg";
import BankFooter from "./BankFooter";
export default function BankDetailsOne() {
  return (
    <div className="px-16 mt-10" style={{ fontSize: 12 }}>
      <div className="rounded-3xl p-4">
        <div className="mb-8">
          <img src={mobility} alt="" width={200} />
        </div>
        <div className="font-bold text-xl mb-6">
          ACCOUNT NAME: CFAO MOBILITY KENYA LIMITED
        </div>
        <div className="" style={{ fontSize: 18, fontWeight: "bold" }}>
          <div className="flex mb-6">
            <div className="mr-6">1.</div>
            <div className="">
              <div className="">CITIBANK N.A.</div>
              <div className="">BRANCH: HEADOFFICE</div>
              <div className="">P.O BOX 30711-00100 NAIROBI</div>
              <div className="">SWIFT CODE: CITIKENA</div>
              <div className="">BANK CODE: 16</div>
              <div className="">BRANCH CODE: 000</div>
              <div className="">KSHS A/C: 102340019</div>
              <div className="">USD A/C: 102340027</div>
              <div className="">JPY A/C: 102340035</div>
              <div className="">EUR A/C: 102340817</div>
              <div className="">IBAN: 021000089</div>
            </div>
          </div>

          <div className="flex mb-6">
            <div className="mr-6">2.</div>
            <div className="">
              <div className="">KENYA COMMERCIAL BANK LTD</div>
              <div className="">MOI AVENUE BRANCH</div>
              <div className="">SWIFT CODE: KCBLKENX</div>
              <div className="">BRANCH CODE: 01100</div>
              <div className="">P.O BOX 39402</div>
              <div className="">NAIROBI</div>
              <div className="">KSHS A/C: 1104655993</div>
            </div>
          </div>

          <div className="flex mb-44">
            <div className="mr-6">3.</div>
            <div className="">
              <div className="">STANDARD CHARTERED BANK KENYA LTD</div>
              <div className="">BRANCH: MARITIME HSE MSA</div>
              <div className="">SWIFT CODE: SCBLKENX</div>
              <div className="">BRANCH CODE: 02008</div>
              <div className="">KSHS A/C: 0102092045700</div>
              <div className="">USD A/C: 8702092045700</div>
              <div className="">JPY A/C: 4202092045700</div>
              <div className="">EUR A/C: 9302092045700</div>
            </div>
          </div>
        </div>
        <div className="">
          <BankFooter />
        </div>
      </div>
    </div>
  );
}
