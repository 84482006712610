import bureau from "../../assets/images/bureau-blank.png";
export default function DocumentFooter() {
  return (
    <div className="flex flex-col">
      <div className="flex">
        <div className="w-24 mr-2">
          <img src={bureau} alt="" />
        </div>
        <div className="">
          <div className="font-bold">Branches:</div>
          <div style={{ fontSize: 10 }}>
            Uhuru Highway, Waiyaki Way Westlands, Ngong Road, Kirinyaga Road,
            Popo Road-South C, Lusaka Road, Mombasa,
          </div>
          <div style={{ fontSize: 10 }}>
            Eldoret, Lodwar, Kisumu, Nyeri, Nanyuki, Nakuru.
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div style={{ fontSize: 6.4 }}>
          CFAO Motors("We") may collect and store your personal data for the
          products/services provided, marketing purposes, market research,
          tracking sales date and in general to improve your experience, We may
          also disclose this information with our dealers and agents for the
          above purposes. We will take all steps reasonably necessary to ensure
          that your data is treated securely and in accordance with current data
          protection laws.
        </div>
        <div style={{ fontSize: 6.4 }}>
          In tandem with this commitment, We require your consent to process
          your personal data and you may withdraw the same conset at any given
          time as you prefer.
        </div>
      </div>

      <div className="flex justify-between">
        <div style={{fontSize: 10}}><span>Sign: </span><span>...........................................................................</span></div>
        <div style={{fontSize: 10}}><span>Date: </span><span>...........................................................................</span></div>
      </div>
    </div>
  );
}
