import { useQuery } from "@tanstack/react-query";
import { Button, Drawer, Space, Tabs } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import type { TabsProps } from "antd";

import { handleFetchContact } from "../../../../api/contacts";
import Something from "../../../../components/shared/Something";
import { FiUser } from "react-icons/fi";
import Moment from "react-moment";
import { MdCorporateFare } from "react-icons/md";
import SocialDetails from "../../../manage/contacts/components/SocialDetails";
import DatesDetails from "../../../manage/contacts/components/DatesDetails";
import BusinessDetails from "../../../manage/contacts/components/BusinessDetails";
import ParticularDetails from "../../../manage/contacts/components/ParticularDetails";
import FileDetails from "../../../manage/contacts/components/FileDetails";
import HobbyDetails from "../../../manage/contacts/components/HobbyDetails";
import formatId from "../../../../utils/formatId";
export default function ContactWrapper({
  setVisible,
  isVisible,
  selected,
}: any) {
  const {
    data: contact,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["contacts", selected?._id],
    queryFn: () => handleFetchContact(selected?._id),
  });

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Particulars`,
      children: contact?.isCompany ? (
        <BusinessDetails contact={contact} />
      ) : (
        <ParticularDetails contact={contact} />
      ),
    },
    {
      key: "2",
      label: `Social Networks`,
      children: <SocialDetails contact={contact} />,
    },
    {
      key: "3",
      label: `Important Dates`,
      children: <DatesDetails contact={contact} />,
    },
    {
      key: "4",
      label: `Files`,
      children: <FileDetails contact={contact} />,
    },
    {
      key: "5",
      label: `Hobbies`,
      children: <HobbyDetails contact={contact} />,
    },
  ];

  const onChange = (key: string) => {};

  return (
    <Drawer
      title={`CONTACT DETAILS`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="font-toyota">
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="px-4 py-2">
            <div className="flex justify-between">
              <div className="flex">
                <div className="flex justify-center items-center w-20 h-20 bg-gray-200 rounded-lg">
                  {contact?.isCompany ? (
                    <div className="flex flex-col justify-center items-center">
                      <MdCorporateFare size={40} />
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center">
                      <FiUser size={40} />
                    </div>
                  )}
                </div>

                <div className="ml-4">
                  <div className="text-lg ">
                    <span className="font-bold mr-1">Name :</span>
                    <span>{contact?.name}</span>
                  </div>
                  <div className="">
                    <div className="flex items-center">
                      <div className="">
                        Category :
                        <span className="text-green-500">
                          {contact?.isCompany ? " Company" : " Individual"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="text-sm">
                    Registered :
                    <span className="text-gray-500 mx-2">
                      {" "}
                      <Moment format={"Do MMM, YYYY"}>
                        {contact?.registered?.date}
                      </Moment>{" "}
                    </span>
                  </div>
                  <div className="text-sm">
                    Contact ID :
                    <span className="text-gray-500 mx-2">
                      {formatId("C", contact?.contactId)}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
}
