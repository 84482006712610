import DocumentHeader from "./DocumentHeader";
import DocumentCustomer from "./DocumentCustomer";
import DocumentPricing from "./DocumentPricing";
import DocumentFooter from "./DocumentFooter";
import DocumentPinvatno from "./DocumentPinvatno";
import DocumentReference from "./DocumentReference";
import "../../assets/styles/custom.css";

export default function AutomarkProforma({ proforma }: any) {
  return (
    <div className="px-16 pt-12 a4-page" style={{ fontSize: 12 }}>
      <DocumentHeader />
      <div className="flex justify-end mt-2 mb-4">
        <DocumentPinvatno />
      </div>
      <div className="flex justify-center">
        <DocumentReference />
      </div>
      <DocumentCustomer proforma={proforma} />
      <DocumentPricing proforma={proforma} />
      <div className="mt-8">
        <DocumentFooter />
      </div>
    </div>
  );
}
