import { Button, Form, Input, message, Popover, Table, Tooltip } from "antd";
import { useContext, useState } from "react";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../../utils/exportExcel";

import { postDataMail } from "../../../services/mail";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Something from "../../../components/shared/Something";
import { handleFetchContacts, handleUpdateContactID } from "../../../api/contacts";
import Wrap from "../Wrap";
import { AiOutlinePlus } from "react-icons/ai";
import CreateContact from "../../manage/contacts/components/CreateContact";
import UpdateContact from "../../manage/contacts/components/UpdateContact";
import DeleteContact from "../../manage/contacts/components/DeleteContact";
import ContactWrapper from "./components/ContactWrapper";
import { AuthContext } from "../../../context/AuthContext";
import Moment from "react-moment";
import formatId from "../../../utils/formatId";

export default function Contacts() {
  const { auth } = useContext(AuthContext);
  const [selected, setSelected] = useState<any>({});
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const createAccess = [2, 3, 4, 9];

  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 10,
    total_pages: 1,
    total_count: 0,
  });

  const {
    data: contacts,
    refetch,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["contacts"],
    queryFn: handleFetchContacts,
  });

  const [emailForm] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: handleUpdateContactID,
    onSuccess: () => {
      message.success("Contact ID Added Successfully");
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handleAddContactId = async (record: any) => {
    await mutate({ _id: record?._id, data: {} });
  }

  const handleRefetch = () => {
    refetch();
  };

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">
          {pagination?.page * pagination?.per_page -
            pagination?.per_page +
            index +
            1}
        </span>
      ),
    },
    {
      title: "Contact ID",
      width: 90,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.contactId ? formatId('C',record?.contactId) : <button onClick={() => handleAddContactId(record)}>-</button>}
          </span>
        );
      },
    },
    {
      title: "Name",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.name || "-"}
          </span>
        );
      },
    },
    {
      title: "Mobile",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.mobile ? record?.mobile : "-"}
          </span>
        );
      },
    },
    {
      title: "Email",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.email ? record?.email : "-"}
          </span>
        );
      },
    },
    {
      title: "TIN",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.tin ? record?.tin : "-"}
          </span>
        );
      },
    },
    {
      title: "Category",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.isCompany ? (
              <div className="">Company</div>
            ) : (
              <div className="">Individual</div>
            )}
          </span>
        );
      },
    },
    {
      title: "Status",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.isExisting ? "Existing Customer" : "New Customer"}
          </span>
        );
      },
    },
    {
      title: "Created",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            <Moment format="DD/MM/YYYY">{record?.createdAt}</Moment>
          </span>
        );
      },
    },
    {
      title: "Segment",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">{record?.industries?.name}</span>
        );
      },
    },
  ];

  const onChange = (params: any) => {
    setPagination({
      ...pagination,
      page: params?.current,
      per_page: params?.pageSize,
      total_count: contacts?.length,
    });
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const mapped = contacts?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name === null ? "-" : item?.name,
        Mobile: item?.mobile === null ? "-" : item?.mobile,
        Email: item?.email === null ? "-" : item?.email,
        Address: item?.address === null ? "-" : item?.address,
        TIN: item?.tin === null ? "-" : item?.tin,
        Category: item?.isCompany ? "Organization" : "Individual",
      };
    });

    const raw = getExportData(mapped);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail({ data: maildata, uri: mailuri });
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  return (
    <Wrap
      active="Contacts"
      actions={
        <>
          <div className="flex items-center">
            <Popover
              content={
                <>
                  <Form
                    name="basic"
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    form={emailForm}
                    onFinish={sendMail}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, message: "Email is Required" }]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                      label="Subject"
                      name="subject"
                      rules={[
                        { required: true, message: "Subject is Required" },
                      ]}
                    >
                      <Input placeholder="Email Subject" />
                    </Form.Item>
                    <div className="flex flex-row justify-between">
                      <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                        <Button type="primary" danger htmlType="submit">
                          {isMailLoading ? "Sending ..." : "Submit"}
                        </Button>
                      </Form.Item>

                      <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                        <Button
                          onClick={() => {
                            toggleMessageModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </>
              }
              title="Message Content"
              trigger="focus"
              placement="bottom"
              open={messageModal}
            >
              <Tooltip title="Send Email">
                <button
                  className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={async () => {
                    toggleMessageModal(true);
                  }}
                >
                  <RiMailSendLine size={20} />
                </button>
              </Tooltip>
            </Popover>
            <button
              className="border bg-white px-4 ml-2 py-1"
              onClick={() =>
                ExportToExcel(
                  contacts?.map((item: any, index: number) => {
                    return {
                      "S/N": index + 1,
                      Name: item?.name === null ? "-" : item?.name,
                      Mobile: item?.mobile === null ? "-" : item?.mobile,
                      Email: item?.email === null ? "-" : item?.email,
                      Address: item?.address === null ? "-" : item?.address,
                      TIN: item?.tin === null ? "-" : item?.tin,
                      Category: item?.isCompany ? "Organization" : "Individual",
                    };
                  }),
                  "Contacts.xlsx"
                )
              }
            >
              <RiFileExcel2Line size={20} />
            </button>

            {createAccess?.includes(auth?.accessLevel) && (
              <Button
                className="bg-white rounded-none ml-2 flex justify-center items-center"
                style={{ fontSize: 12 }}
                onClick={() => setCreateModal(true)}
              >
                <div className="">
                  <AiOutlinePlus size={12} />
                </div>
                <div className="ml-1 pt-1">New</div>
              </Button>
            )}
          </div>
        </>
      }
    >
      {isError ? (
        <Something refetch={refetch} isFetching={isFetching} />
      ) : (
        <div className="grid gap-x-0 grid-cols-12 border bg-white m-2">
          <div className="col-span-12 sm:col-span-12 md:col-span-12">
            <Table
              size="small"
              dataSource={contacts === undefined ? [] : contacts}
              columns={columns}
              scroll={{ y: 760, x: 0 }}
              rowKey="id"
              onRow={(record) => {
                return {
                  onClick: () => {
                    setSelected({
                      ...record,
                    });
                    setDetailsModal(true);
                  },
                };
              }}
              pagination={{
                current: pagination?.page,
                pageSize: pagination?.per_page,
                total: pagination?.total_count,
              }}
              onChange={onChange}
              rowClassName={"hover:cursor-pointer hover:bg-red-50"}
              loading={isLoading}
            />

            <CreateContact
              isVisible={createModal}
              setVisible={setCreateModal}
              refetch={handleRefetch}
            />

            <ContactWrapper
              isVisible={detailsModal}
              setVisible={setDetailsModal}
              refetch={handleRefetch}
              selected={selected}
            />

            <UpdateContact
              isVisible={updateModal}
              setVisible={setUpdateModal}
              selected={selected}
            />

            <DeleteContact
              isVisible={deleteModal}
              setVisible={setDeleteModal}
              selected={selected}
            />
          </div>
        </div>
      )}
    </Wrap>
  );
}
