import { Popover, Button, DatePicker } from 'antd'
import  { useContext, useState } from "react";
import moment, { Moment } from "moment";
import { BiExport } from "react-icons/bi";
import { AuthContext } from '../../../../context/AuthContext';
import { fetchDataAuto } from '../../../../services/auto';
import { ExportToExcel } from '../../../../utils/exportExcel';

const { RangePicker } = DatePicker;

const ExportContent = (props: any) => {
  const { user, token, expire } = useContext(AuthContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const {  hide } = props;
  const dateFormat = "DD/MM/YYYY";
  const timeFormat = "HH:mm";
  const tomorrow: Moment = moment().add(1, "day");

  const [filterDate, setFilterDate] = useState<any>([tomorrow, tomorrow]);

  const handleFetchBookings = async () => {
    const site = user?.site?._id || "6224dcf60c5c49d9fec9218f";
    setLoading(true);
    const uri = `bookings/export/${site}?start=${moment(filterDate[0]).format(
      "YYYYMMDD"
    )}&end=${moment(filterDate[1]).format("YYYYMMDD")}&all=0`;
    const data = await fetchDataAuto({ uri, token, expire });

    ExportToExcel(
      data?.payload?.map((booking: any) => ({
        "Booking Number": booking?.bookingno,
        Customer: booking?.customer_name,
        Mobile: booking?.customer_mobile,
        Email: booking?.customer_email,
        Address: booking?.customer_address,
        Date: moment(booking?.date).format(dateFormat),
        Time: `${moment(booking?.slottime?.starttime).format(
          timeFormat
        )} - ${moment(booking?.slottime?.endtime).format(timeFormat)} `,
        Service: booking?.description,
        Model: booking?.model,
        "Registration Number": booking?.vehicle,
        Status: booking?.status,
        Advisor: `${booking?.assignedTo?.firstname} ${booking?.assignedTo?.lastname}`,
        Mileage: booking?.mileage,
        Wip: booking?.wip,
        "Created Date": moment(booking?.createdAt).format(
          `${dateFormat} ${timeFormat}`
        ),
        "Customer Remarks": booking?.customer_comments,
      })),
      `Bookings from ${moment(filterDate[0]).format(dateFormat)} to ${moment(
        filterDate[1]
      ).format(dateFormat)}.xlsx`
    );
    hide();
    setLoading(false);
  };

  return (
    <div>
      <RangePicker
        defaultValue={filterDate}
        format={dateFormat}
        onChange={(date: any) => {
          setFilterDate(date);
        }}
      />

      <div className="flex flex-row justify-between mt-2">
        <Button
          type="primary"
          onClick={() => {
            handleFetchBookings();
          }}
        >
         {isLoading ? "Exporting ... " : "Export"}
        </Button>

        <Button onClick={hide}>Cancel</Button>
      </div>
    </div>
  );
};


const ExportProforma = (props: any) => {


    const [isVisible, toggleVisibility] = useState(false)

    const hide = () => {
        toggleVisibility(false)
    }

    const show = () => {
        toggleVisibility(true)
    }

    return (
        <Popover
            content={<ExportContent {...props} hide={hide} />}
            title="Select Date To Export"
            trigger="hover"
            open={isVisible}
        >
            <button 
            className="border rounded-md px-2 py-1 flex items-center mx-2" 
            onClick={show}
            >
                <BiExport />
                <span className='mx-1'>Export </span>
            </button>
            
        </Popover>
    )
}

export default ExportProforma