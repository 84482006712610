import { upperCase } from "lodash";
import getProformaTotals from "../../utils/proformaTotals";
import roundKES from "../../utils/roundKES";

export default function DocumentPricing({ proforma }: any) {
  const { discount, total, vat, unit, net } = getProformaTotals(proforma);

  return (
    <div className="" style={{fontSize: 11}}>
      <div className="border-r border-l border-b border-black">
        <div className="grid grid-cols-12 border-b border-black">
          <div className="col-span-2 border-r border-black px-1 font-bold">
            Seller Dealer:
          </div>
          <div className="col-span-2 border-r border-black px-1">
            Used Vehicles
          </div>
          <div className="col-span-2 border-r border-black px-1"></div>
          <div className="col-span-2 border-r border-black px-1"></div>
          <div className="col-span-2 border-r border-black px-1 font-bold">
            Sales Exec:
          </div>
          <div className="col-span-2 px-1">Oyiaka Jael</div>
        </div>
        <div className="grid grid-cols-12 border-b border-black">
          <div className="col-span-2 border-r border-black px-1 font-bold">
            Duty:
          </div>
          <div className="col-span-2 border-r border-black px-1"></div>
          <div className="col-span-2 border-r border-black px-1"></div>
          <div className="col-span-2 border-r border-black px-1"></div>
          <div className="col-span-2 border-r border-black px-1 font-bold">
            Order No:
          </div>
          <div className="col-span-2 px-1">300148922</div>
        </div>
        <div className="grid grid-cols-12 border-b border-black">
          <div className="col-span-2 border-r border-black px-1 font-bold">
            Chassis No:
          </div>
          <div className="col-span-2 border-r border-black px-1"></div>
          <div className="col-span-2 border-r border-black px-1 font-bold">
            Engine No:
          </div>
          <div className="col-span-2 border-r border-black px-1"></div>
          <div className="col-span-2 border-r border-black px-1 font-bold">
            YOM:
          </div>
          <div className="col-span-2 px-1"></div>
        </div>
        <div className="grid grid-cols-12 border-b border-black">
          <div className="col-span-2 border-r border-black px-1 text-white">
            1
          </div>
          <div className="col-span-2 border-r border-black px-1"></div>
          <div className="col-span-2 border-r border-black px-1"></div>
          <div className="col-span-2 border-r border-black px-1"></div>
          <div className="col-span-2 border-r border-black px-1"></div>
          <div className="col-span-2 px-1"></div>
        </div>
        <div className="grid grid-cols-12 border-b border-black">
          <div className="col-span-2 border-r border-black px-1 text-white">
            1
          </div>
          <div className="col-span-2 border-r border-black px-1"></div>
          <div className="col-span-2 border-r border-black px-1"></div>
          <div className="col-span-2 border-r border-black px-1"></div>
          <div className="col-span-2 border-r border-black px-1"></div>
          <div className="col-span-2 px-1"></div>
        </div>
      </div>

      <div className="grid grid-cols-12 border-b border-black">
        <div className="col-span-1  border-l border-black font-bold px-1">
          Item
        </div>
        <div className="col-span-3 font-bold">
          <span>Description</span> <span className="mx-3">Spec</span>
        </div>
        <div className="col-span-1 text-center font-bold">Qty</div>
        <div className="col-span-2 text-right pr-2 font-bold">Unit Price</div>
        <div className="col-span-1 text-center font-bold">CURR</div>
        <div className="col-span-1 text-center font-bold">Disc</div>
        <div className="col-span-1 text-center font-bold">Vat</div>
        <div className="col-span-2 text-right border-r border-black font-bold px-1">
          Amount
        </div>
      </div>

      <div className="border border-black">
        {/* VEHICLES */}
        <div className="mb-4" style={{fontSize: 11}}>
          {proforma?.vehicles?.map((vehicle: any, index: number) => (
            <div className="grid grid-cols-12" key={index}>
              <div className="col-span-1 px-1">1</div>
              <div className="col-span-3">
                {vehicle?.year} {upperCase(vehicle?.model?.name)} chassis No.{" "}
                {vehicle?.chassis}
              </div>
              <div className="col-span-1 text-center ">1.00</div>
              <div className="col-span-2 text-right pr-2 ">
                {roundKES(vehicle?.prices?.exclusive)}
              </div>
              <div className="col-span-1 text-center ">KES</div>
              <div className="col-span-1 text-center ">0.00</div>
              <div className="col-span-1 text-center "></div>
              <div className="col-span-2 text-right px-1">
                {roundKES(vehicle?.prices?.exclusive)}
              </div>
            </div>
          ))}
        </div>
        {/* REGISTRATION */}
        <div className="mb-2">
          <div className="grid grid-cols-12">
            <div className="col-span-1 text-center"></div>
            <div className="col-span-3 ">Registration</div>
            <div className="col-span-1 text-center"></div>
            <div className="col-span-2 text-right pr-2"></div>
            <div className="col-span-1 text-center"></div>
            <div className="col-span-1 text-center"></div>
            <div className="col-span-1 text-center"></div>
            <div className="col-span-2 text-right px-1">0.00</div>
          </div>
        </div>
        {/* VATABLE */}
        <div className="font-bold px-2 h-56">* Vatable items</div>
        {/* SUMMARY */}
      </div>
      <div className="border-t border-black" style={{fontSize: 11}}>
        <div className="grid grid-cols-12">
          <div className="col-span-2 text-center border-r border-l border-b border-black font-bold">
            Date Payment Due:
          </div>
          <div className="col-span-3 border-r border-b border-black px-1">
            23.09.2024
          </div>
          <div className="col-span-1 text-center border-r border-b border-black font-bold">
            Vat Rate:
          </div>
          <div className="col-span-1 text-right pr-2 border-r border-b border-black">
            0.0%
          </div>
          <div className="col-span-1 text-center "></div>
          <div className="col-span-2 font-bold border-r border-l border-b border-black px-1">
            Net Total:
          </div>
          <div className="col-span-2 text-right border-b border-r border-black px-1">
            {roundKES(total)}
          </div>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-2 text-center border-r border-l border-b border-black font-bold">
            Terms of Payment:
          </div>
          <div className="col-span-3 border-r border-b border-black px-1">
            7 Days from Doc Date
          </div>
          <div className="col-span-1 text-center border-r border-b border-black"></div>
          <div className="col-span-1 text-right border-r border-b border-black"></div>
          <div className="col-span-1 text-cente "></div>
          <div className="col-span-2 font-bold border-r border-l border-b border-black px-1">
            VAT
          </div>
          <div className="col-span-2 text-right border-b border-r border-black px-1">
            {roundKES(vat)}
          </div>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-2 text-center font-bold"></div>
          <div className="col-span-3 px-1"></div>
          <div className="col-span-1 text-center"></div>
          <div className="col-span-1 text-right"></div>
          <div className="col-span-1 text-center "></div>
          <div className="col-span-2 font-bold border-r border-l border-b border-black px-1">
            Total
          </div>
          <div className="col-span-2 text-right border-b border-r border-black px-1">
            {roundKES(net)}
          </div>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-2 text-center font-bold"></div>
          <div className="col-span-3 px-1"></div>
          <div className="col-span-1 text-center"></div>
          <div className="col-span-1 text-right pr-2"></div>
          <div className="col-span-1 text-center "></div>
          <div className="col-span-2 font-bold border-r border-l border-b border-black px-1">
            Deposit
          </div>
          <div className="col-span-2 text-right border-b border-r border-black px-1">
            {roundKES(0)}
          </div>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-2 text-center font-bold"></div>
          <div className="col-span-3 px-1"></div>
          <div className="col-span-1 text-center"></div>
          <div className="col-span-1 text-right pr-2"></div>
          <div className="col-span-1 text-center "></div>
          <div className="col-span-2 font-bold border-r border-l border-b border-black px-1">
            Net Due
          </div>
          <div className="col-span-2 text-right border-b border-r border-black px-1">
            {roundKES(net)}
          </div>
        </div>
      </div>
    </div>
  );
}
