import header from "../../assets/images/header.png";
export default function DocumentHeader() {
  return (
    <div className="flex flex-col items-center">
      <div className="text-automark-dark font-bold text-3xl mb-2">
        CFAO MOBILITY KENYA LIMITED
      </div>
      <div className="mb-1">
        <img src={header} alt="" />
      </div>
      <div className="">
        <span style={{fontSize: 8}}>Uhuru Highway (Opp. Nyayo Stadium), P.O Box 3391 - 00506 Nairobi, Kenya Tel: 020 6967000, GSM: 0719 029000/14/16, <span className="font-bold">0800 723 222(tool free)</span> Email: enquirieskenya@cfao.com</span>
      </div>
    </div>
  );
}
