import { useContext, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { AuthContext } from "../../context/AuthContext";
import Moment from "react-moment";

export default function SalesDetails({ vehicle, flags, editable }: any) {
  const { auth } = useContext(AuthContext);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const editAccess = [4, 9];

  if (vehicle?.status !== "sold") return null;

  return (
    <div>
      <div className="border-b border-t px-4 py-1 flex justify-between bg-gray-100">
        <div className="">Sales Details</div>
        {editAccess?.includes(auth?.accessLevel) && (
          <div className="">
            {editable && (
              <button
                onClick={() => setUpdateModal(true)}
                className="border p-1 rounded-md"
              >
                <FiEdit />
              </button>
            )}
          </div>
        )}
      </div>
      <div className="border-t-4"></div>
      <div className="p-4" style={{ fontSize: 14 }}>
        <div className="font-bold">Customer / Sold To:-</div>
        <div className="flex justify-between mb-1">
          <div className="">Customer Name</div>
          <div className="">{vehicle?.owner?.name || "-"}</div>
        </div>
        <div className="flex justify-between">
          <div className="">Invoice Date</div>
          <div className="">
            {vehicle?.sales?.sold_date && (
              <Moment format="Do MMM, YYYY">{vehicle?.sales?.sold_date}</Moment>
            )}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="">Invoice Number</div>
          <div className="">{vehicle?.sales?.invoice || "-"}</div>
        </div>
      </div>


      <div className="p-4" style={{ fontSize: 14 }}>
        <div className="font-bold">Sales Consultant / Sold By:-</div>
        <div className="flex justify-between mb-1">
          <div className="">Consultant Name</div>
          <div className="">{vehicle?.sales?.consultant?.name|| "-"}</div>
        </div>
        <div className="flex justify-between">
          <div className="">Consultant Email</div>
          <div className="">
            {vehicle?.sales?.consultant?.email}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="">Consultant Mobile</div>
          <div className="">{vehicle?.sales?.consultant?.mobile || "-"}</div>
        </div>
      </div>


    </div>
  );
}
