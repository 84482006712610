const getTotal = (proforma: any) => {
  let total = 0;
  if (proforma?.vehicles) {
    proforma.vehicles.forEach((vehicle: any) => {
      total = total + vehicle?.prices?.exclusive;
    });
  }
  return total;
};

const getProformaTotals: any = (proforma: any) => {
  let discount = 0;
  let vat = 0;
  const total = getTotal(proforma);
  if (proforma?.discount?.isPercentage) {
    discount = total * proforma?.discount?.value;
  } else {
    discount = proforma?.discount?.amount;
  }
  vat = (total - discount) * proforma?.vat?.amount;


  const unit = total - discount;

  const net = unit + vat;

  return { total, discount, vat, unit, net };
};


export default getProformaTotals;
