export default function DocumentPinvatno() {
  return (
    <div style={{fontSize: 12}}>
        <div className="font-bold">IFMIS No. 503</div>
        <div className="border border-black p-1 w-32">
            <div className="">PIN No. P05112625X</div>
            <div className="">VAT No. 0111432U</div>
        </div>
    </div>
  )
}
