import banner from "../../assets/images/header-cropped.png"

export default function BankFooter() {
  return (
    <div className='flex flex-col text-black'>
        <div className="mb-1">
            <div className="text-gray-600 font-bold">CFAO MOBILITY KENYA LIMITED</div>
            <div className="" style={{fontSize: 10}}>Uhuru Highway (Opp.Nyayo Stadium), P.O Box 3391 - 00506 Nairobi, Kenya</div>
            <div className="" style={{fontSize: 10}}>Tel: 020 6967000, GSM: 0719 029000/14/16, <span className='font-bold'>0800 723 222(tool free)</span></div>
            <div className="" style={{fontSize: 10}}>Email: enquirieskenya@cfao.com</div>
        </div>
        <div className="">
            <img src={banner} alt="" />
        </div>
        <div className="" style={{fontSize: 9}}>
            Directors: A.Reel (Managing Director), DNO Awori (Chairman), J.Anya, E.Conan**, F.Creisquer**, M.Hirschfeld**, M.Yamashita* (*Japanese, **French)
        </div>
    </div>
  )
}
