import { useState } from "react";
import image from "../../assets/images/photo.png";
import AddImage from "../../pages/manage/vehicles/components/AddImage";

export default function FeatureMainImage({
  thumbnail,
  vehicle,
  editable,
}: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  return (
    <div className="flex justify-center items-center w-full h-full">
      <div
        className="flex justify-center items-center"
        style={{ backgroundColor: "#ECECEC" }}
      >
        <button
          className=""
          onClick={() => {
            if (editable) {
              setVisible(true);
              setSelected({
                location: "F1",
                isMain: true,
                title: "Front 1",
              });
            }
          }}
        >
          <img
            src={thumbnail || image}
            alt=""
            className="w-full aspect-[4/3] object-cover"
          />
        </button>
      </div>
      <AddImage
        isVisible={isVisible}
        setVisible={setVisible}
        selected={selected}
        chassis={vehicle?.chassis}
      />
    </div>
  );
}
