export default function TermsAndConditions() {
  return (
    <div className="px-16 mt-8 a4-page" style={{ fontSize: 12 }}>
      <div className="border-2 border-black rounded-3xl p-4">
        <div className="font-bold mb-4">TERMS AND CONDITIONS</div>
        <div className="mb-6">
          <div className="font-bold mb-2">INVOICE</div>
          <div className="flex mb-1">
            <div className="w-6">1.</div>
            <div className="flex-1">
              All amounts payable within 30 days from invoice date.
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">2.</div>
            <div className="flex-1">
              Cheques are payable to Cfao Motors Kenya
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">3.</div>
            <div className="flex-1">
              3% Interest per month will be levied on overdue accounts
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">4.</div>
            <div className="flex-1">
              The Company shall have a general as well as particular lien onall
              goods for updaid accounts.
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">5.</div>
            <div className="flex-1">
              All goods must be checked and verified at the time of delivery.
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">6.</div>
            <div className="flex-1">
              Parts returned in perfect condition within 3 days from the date of
              purchase will be subject to 10% handling charges, Parts returned
              after 3 days will not be accepted.
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">7.</div>
            <div className="flex-1">
              Electrical parts once sold will not be accepted back.
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">8.</div>
            <div className="flex-1">Quotations are valid for 14 days only.</div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">9.</div>
            <div className="flex-1">
              Any dispute should be communicated in writing within 7 days.
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">10.</div>
            <div className="flex-1">
              The rulling pricing shall be that applicable on the delivery date
              and may vary depending on prevailing conditions at the date of
              delivery. Thses conditions include but are not limited to changes
              in the manufacture's price rate of exchange, levies or duties,
              shipping insurance or transport rates. Any changes to the purchase
              price shall be adjusted and payable by the customer on demand.
            </div>
          </div>
        </div>

        <div className="">
          <div className="font-bold mb-2">LOCAL PURCHASE ORDER</div>
          <div className="flex mb-1">
            <div className="w-6">1.</div>
            <div className="flex-1">
              LPO's are valid for 60 days from date of issue or as stated on the
              face of this document.
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">2.</div>
            <div className="flex-1">
              Orders not executed within the specified period are liable for
              cancellation.
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">3.</div>
            <div className="flex-1">
              LPOs must be quoted on all invoices, delivery notes and
              correspondence concerning goods and services.
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">4.</div>
            <div className="flex-1">
              Each order must have a separate invoice.
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">5.</div>
            <div className="flex-1">
              Any goods which are considered unsuitable will be returned for a
              full credit note.
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">6.</div>
            <div className="flex-1">
              To expedite payment, send delivery/invoice directly to the
              ordering department.
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">7.</div>
            <div className="flex-1">
              No alteration will be recognised unless signed by the signatory of
              this order.
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">8.</div>
            <div className="flex-1">
              Supply of goods and services should be stated in Cfao Motors Kenya
              supplier's guidelines.
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">9.</div>
            <div className="flex-1">
              Any invoice more than (1) year old presented to Cfao motors will
              not be paid, unless if there was a dispute being handled by both
              parties.
            </div>
          </div>

          <div className="flex mb-1">
            <div className="w-6">10.</div>
            <div className="flex-1">
              Supplier statements should be sent to Cfao Motors Kenya Finance
              Department by the 5th of the following month.
            </div>
          </div>
          <div className="flex mb-1">
            <div className="w-6">11.</div>
            <div className="flex-1">
              Partial invoicing for goods or services in not permitted.
            </div>
          </div>
          <div className="flex mb-1">
            <div className="w-6">12.</div>
            <div className="flex-1">
              Please note we are withholding VAT Agent ( All payments to you
              that are vatable will be less than 2% VAT value).
            </div>
          </div>
          <div className="flex mb-1">
            <div className="w-6">13.</div>
            <div className="flex-1">
              Please attach a copy of purchase order to the invoice/delivery
              note.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
