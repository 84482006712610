import { Button, Drawer, Space } from "antd";
import { useContext, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsCheck2, BsPrinter } from "react-icons/bs";
import ProformaEdit from "./ProformaEdit";
import AutomarkProforma from "../../../components/automark/AutomarkProforma";
import ReactToPrint from "react-to-print";
import { AuthContext } from "../../../context/AuthContext";
import TermsAndConditions from "../../../components/automark/TermsAndConditions";
import BankDetailsOne from "../../../components/automark/BankDetailsOne";
import BankDetailsTwo from "../../../components/automark/BankDetailsTwo";
import BankDetailsThree from "../../../components/automark/BankDetailsThree";

export default function ProformaDetails({
  isVisible,
  setVisible,
  selected,
}: any) {
  const { auth } = useContext(AuthContext);

  const editAccess = [2, 4, 9];

  const [editMode, setEditMode] = useState<boolean>(false);

  const docRef = useRef(null);

  if (!selected) {
    return <></>;
  }

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">
            <span className="font-bold">PROFORMA : </span>
          </span>
          <div className="flex text-gray-500 px-2 uppercase">
            {selected?.contact?.name}
          </div>
        </div>
      }
      placement={"right"}
      width={900}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <ReactToPrint
            trigger={() => {
              return (
                <button className="flex items-center  border px-2 py-1 rounded-md hover:bg-blue-50">
                  <BsPrinter />
                  <span className="ml-1">Print</span>
                </button>
              );
            }}
            content={() => docRef.current}
            documentTitle={`Proforma`}
          />
          {selected?.status?.state !== "approved" && (
            <div className="">
              {editAccess?.includes(auth?.accessLevel) && (
                <div className="">
                  {editMode ? (
                    <div
                      onClick={() => setEditMode(false)}
                      className="bg-blue-200 h-8 flex items-center rounded-lg cursor-pointer"
                    >
                      <div className="flex px-1">
                        <div className="rounded-lg h-6 mx-1">Edit</div>
                        <div className="rounded-lg bg-white h-6 w-6 flex justify-center items-center">
                          <BsCheck2 />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() => setEditMode(true)}
                      className="bg-gray-200 h-8 flex items-center rounded-lg cursor-pointer"
                    >
                      <div className="flex px-1">
                        <div className="rounded-lg bg-white h-6 w-6 flex justify-center items-center">
                          <BsCheck2 />
                        </div>
                        <div className="rounded-lg h-6 mx-1">Edit</div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          <div className="border-l border-gray-400 h-4"></div>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="mb-64">
        {editMode ? (
          <ProformaEdit proforma={selected} />
        ) : (
          <div className="-mt-10">
            <div className="" ref={docRef}>
              <AutomarkProforma proforma={selected} />
              <TermsAndConditions />
              <BankDetailsOne />
              <BankDetailsTwo />
              <BankDetailsThree />
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
}
