import { useQuery } from "@tanstack/react-query";
import { BsChevronLeft } from "react-icons/bs";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Manage from "..";
import { handleFetchVehicle } from "../../../api/vehicles";
import Something from "../../../components/shared/Something";
import VehicleImages from "./components/VehicleImages";
import { useEffect, useState } from "react";
import UpdateLocation from "./components/UpdateLocation";
import Documents from "./components/Documents";
import Prices from "./components/Prices";
import Locations from "./components/Locations";
import ServiceHistory from "../../../components/vehicle/ServiceHistory";
import FeatureSetTwo from "../../../components/vehicle/FeatureSetTwo";
import FeatureSetOne from "../../../components/vehicle/FeatureSetOne";
import FeatureHeader from "../../../components/vehicle/FeatureHeader";
import FeatureMainImage from "../../../components/vehicle/FeatureMainImage";
import { FiEdit } from "react-icons/fi";
import DetailsUpdate from "../../../components/vehicle/DetailsUpdate";
import SpecsUpdate from "../../../components/vehicle/SpecsUpdate";
import FeaturesUpdate from "../../../components/vehicle/FeaturesUpdate";
import Shipment from "../../../components/vehicle/Shipment";
import Publishment from "../../../components/vehicle/Publishment";
import Availability from "../../../components/vehicle/Availability";
import AgeingWidget from "./components/AgeingWidget";
import { IoCloudUploadSharp } from "react-icons/io5";
import MultiUpload from "./components/MultiUpload";
import FeatureSetThree from "../../../components/vehicle/FeatureSetThree";
import OtherSpecsUpdate from "../../../components/vehicle/OtherSpecsUpdate";
import FeatureSetFour from "../../../components/vehicle/FeatureSetFour";
import SalesDetails from "../../../components/vehicle/SalesDetails";
import ReservationsDetails from "../../../components/vehicle/ReservationsDetails";

export default function OneVehicle() {
  const [locationModal, setLocationModal] = useState<boolean>(false);
  const [bulkModal, setBulkModal] = useState<boolean>(false);
  const [updateDetailsModal, setUpdateDetailsModal] = useState<boolean>(false);
  const [updateFeaturesModal, setUpdateFeaturesModal] =
    useState<boolean>(false);
  const [updateSpecsModal, setUpdateSpecsModal] = useState<boolean>(false);
  const [updateOtherSpecsModal, setUpdateOtherSpecsModal] =
    useState<boolean>(false);
  const { chassis } = useParams();

  const {
    data: vehicle,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["vehicles", chassis],
    queryFn: () => handleFetchVehicle(chassis),
  });

  const [isEditable, setIsEditable] = useState<boolean>(
    vehicle?.status === "sold" ? false : true
  );

  useEffect(() => {
    setIsEditable(vehicle?.status === "sold" ? false : true);
  }, [vehicle]);

  return (
    <Manage
      title={"Vehicle"}
      subtitle={"Details"}
      description={`Features and Vehicle Information`}
      nested={
        <Link to={"/manage/stock"}>
          <div className="flex items-center">
            <BsChevronLeft />
          </div>
        </Link>
      }
      actions={<div className="flex"></div>}
    >
      <>
        <div className="">
          <div className="h-8 bg-gray-50 flex items-center border-b px-3">
            <Link to={"/manage/stock"}>
              <div className="flex items-center">
                <BsChevronLeft />
                <span className="ml-1 text-xs">Back</span>
              </div>
            </Link>
          </div>
        </div>
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="p-2">
            <div className="grid gap-x-2 grid-cols-12">
              <div className="col-span-12 sm:col-span-9 md:col-span-9 border p-2">
                <div className="border-b mb-2 pb-1">
                  <FeatureHeader vehicle={vehicle} editable={true} />
                </div>
                <div className="grid gap-x-2 grid-cols-12 mb-4">
                  <div className="col-span-12 sm:col-span-6 md:col-span-6 bg-gray-200">
                    <FeatureMainImage
                      thumbnail={vehicle?.thumbnail}
                      vehicle={vehicle}
                      editable={true}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6 md:col-span-6">
                    <VehicleImages vehicle={vehicle} editable={true} />
                  </div>
                </div>

                <div className="grid gap-x-2 grid-cols-12 mb-4 -mt-4 ">
                  {isEditable && (
                    <div className="col-span-12 sm:col-span-6 md:col-span-6 bg-gray-200 border-t border-gray-100">
                      <button
                        onClick={() => setBulkModal(true)}
                        className="bg-gray-200  p-2 flex"
                      >
                        <IoCloudUploadSharp />
                        <span className="w-full ml-1">Bulk Upload Images</span>
                      </button>
                    </div>
                  )}
                </div>

                <div className="grid gap-x-2 grid-cols-12">
                  <div className="col-span-12 sm:col-span-6 md:col-span-6">
                    <div className="bg-gray-100 p-2 flex justify-between items-center">
                      <div className="">Vehicle Details</div>
                      {isEditable && (
                        <button
                          onClick={() => {
                            setUpdateDetailsModal(true);
                          }}
                          className=" p-1 rounded-md"
                        >
                          <FiEdit />
                        </button>
                      )}
                    </div>
                    <FeatureSetOne vehicle={vehicle} />
                  </div>
                  <div className="col-span-12 sm:col-span-6 md:col-span-6">
                    <div className="bg-gray-100 p-2 flex justify-between items-center">
                      <div className="">Vehicle General Specs</div>
                      {isEditable && (
                        <button
                          onClick={() => {
                            setUpdateSpecsModal(true);
                          }}
                          className=" p-1 rounded-md"
                        >
                          <FiEdit />
                        </button>
                      )}
                    </div>
                    <FeatureSetTwo vehicle={vehicle} />
                  </div>

                  <div className="col-span-12 sm:col-span-6 md:col-span-6">
                    <div className="bg-gray-100 p-2 flex justify-between items-center">
                      <div className="">Vehicle Other Specs</div>
                      {isEditable && (
                        <button
                          onClick={() => {
                            setUpdateOtherSpecsModal(true);
                          }}
                          className=" p-1 rounded-md"
                        >
                          <FiEdit />
                        </button>
                      )}
                    </div>
                    <FeatureSetThree vehicle={vehicle} />
                  </div>
                  <div className="col-span-12 sm:col-span-6 md:col-span-6">
                    <div className="bg-gray-100 p-2 flex justify-between items-center">
                      <div className="">Vehicle Features</div>
                      {isEditable && (
                        <button
                          onClick={() => {
                            setUpdateFeaturesModal(true);
                          }}
                          className=" p-1 rounded-md"
                        >
                          <FiEdit />
                        </button>
                      )}
                    </div>
                    <FeatureSetFour vehicle={vehicle} />
                  </div>
                </div>
                <div className="mt-8" style={{ fontSize: 14 }}>
                  <ServiceHistory chassis={vehicle?.chassis} />
                </div>
              </div>

              <div className="col-span-12 sm:col-span-3 md:col-span-3 border">
                {/* <SpecCheck vehicle={vehicle} /> */}

                <div className="">
                  <Publishment
                    vehicle={vehicle}
                    flags={vehicle?.flags}
                    chassis={vehicle?.chassis}
                    editable={true}
                  />
                  <SalesDetails vehicle={vehicle} editable={false} />
                  <Documents
                    documents={vehicle?.documents}
                    chassis={vehicle?.chassis}
                    editable={isEditable}
                  />
                  <Shipment
                    arrival={vehicle?.arrival}
                    shipment={vehicle?.shipment}
                    chassis={vehicle?.chassis}
                    editable={isEditable}
                  />

                  <Prices
                    prices={vehicle?.prices}
                    history={vehicle?.priceHistory}
                    chassis={vehicle?.chassis}
                    editable={true}
                  />

                  <MultiUpload
                    isVisible={bulkModal}
                    setVisible={setBulkModal}
                    chassis={vehicle?.chassis}
                    vehicle={vehicle}
                  />
                  <Availability
                    editable={true}
                    chassis={vehicle?.chassis}
                    availability={vehicle?.availability}
                  />
                  <AgeingWidget vehicle={vehicle} />
                  <Locations
                    prices={vehicle?.prices}
                    movement={vehicle?.movement}
                    vehicle={vehicle}
                    chassis={vehicle?.chassis}
                    editable={true}
                  />
                  <ReservationsDetails vehicle={vehicle} editable={false} />
                </div>
              </div>
            </div>
          </div>
        )}

        <UpdateLocation
          isVisible={locationModal}
          setVisible={setLocationModal}
          vehicle={vehicle}
          chassis={chassis}
        />

        <DetailsUpdate
          isVisible={updateDetailsModal}
          setVisible={setUpdateDetailsModal}
          vehicle={vehicle}
          chassis={chassis}
        />

        <SpecsUpdate
          isVisible={updateSpecsModal}
          setVisible={setUpdateSpecsModal}
          vehicle={vehicle}
          chassis={chassis}
        />

        <OtherSpecsUpdate
          isVisible={updateOtherSpecsModal}
          setVisible={setUpdateOtherSpecsModal}
          vehicle={vehicle}
          chassis={chassis}
        />

        <FeaturesUpdate
          isVisible={updateFeaturesModal}
          setVisible={setUpdateFeaturesModal}
          vehicle={vehicle}
          chassis={chassis}
        />
      </>
    </Manage>
  );
}
