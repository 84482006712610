import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchContacts = async () => {
  const uri = `api/crm/contacts`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchContact = async (id: any) => {
  const uri = `api/crm/contacts/${id}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateContact = async (data: any) => {
  const uri = `api/crm/contacts`;
  const response = postDataAuto({ data, uri });
  return response;
};

const handleUpdateContact = async ({ data, _id }: any) => {
  const uri = `api/crm/contacts/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};


const handleUpdateContactID = async ({ data, _id }: any) => {
  const uri = `api/crm/contacts/${_id}/contact-id`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteContact = async ({ _id }: any) => {
  const uri = `api/crm/contacts/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};


const handleCreateContactDate = async ({ data, _id }: any) => {
  const uri = `api/crm/contacts/${_id}/dates`;
  const response = postDataAuto({ data, uri });
  return response;
};

const handleUpdateContactDate = async ({ data, _id, date_id }: any) => {
  const uri = `api/crm/contacts/${_id}/dates/${date_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteContactDate = async ({ data, _id, date_id }: any) => {
  const uri = `api/crm/contacts/${_id}/dates/${date_id}`;
  const response = deleteDataAuto({ data, uri });
  return response;
};


const handleCreateContactHobby = async ({ data, _id }: any) => {
  const uri = `api/crm/contacts/${_id}/hobbies`;
  const response = postDataAuto({ data, uri });
  return response;
};

const handleUpdateContactHobby = async ({ data, _id, hobby_id }: any) => {
  const uri = `api/crm/contacts/${_id}/hobbies/${hobby_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteContactHobby = async ({ _id, hobby_id }: any) => {
  const uri = `api/crm/contacts/${_id}/hobbies/${hobby_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

const handleUploadContactFile = async ({ data, _id }: any) => {
  const uri = `api/crm/contacts/${_id}/upload`;
  const response = postDataAuto({ data, uri });
  return response;
};

export {
  handleFetchContacts,
  handleFetchContact,
  handleCreateContact,
  handleUpdateContact,
  handleDeleteContact,
  handleUpdateContactID,
  handleCreateContactDate,
  handleUpdateContactDate,
  handleDeleteContactDate,
  handleCreateContactHobby,
  handleUpdateContactHobby,
  handleDeleteContactHobby,
  handleUploadContactFile,
};
