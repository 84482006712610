export default function DocumentReference() {
  return (
    <div style={{fontSize: 12}} className="border-t border-r border-l border-black w-3/5 p-1">
      <div className="text-center mb-2">Vehicle Proforma Invoice</div>
      <div className="flex mb-1">
        <div className="w-1/2 flex">
          <div className="font-bold underline w-28">Document: </div>{" "}
          <div>70283736</div>
        </div>
        <div className="w-1/2 flex">
          <div className="font-bold underline w-28">Original Invoice:</div>{" "}
          <div>70283736</div>
        </div>
      </div>
      <div className="flex mb-1">
        <div className="w-1/2 flex">
          <div className="font-bold underline w-28">Document Date: </div>{" "}
          <div>20.09.2024</div>
        </div>
      </div>
    </div>
  );
}
