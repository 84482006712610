import { Button, Modal, Form, Row, Col, Input, message, Select } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleCreateVehicle } from "../../../../api/vehicles";
import { handleFetchModels } from "../../../../api/models";
import { handleFetchSources } from "../../../../api/sources";
import { handleFetchLocations } from "../../../../api/locations";

const { Option } = Select;

export default function CreateVehicle({ isVisible, setVisible }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleCreateVehicle,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles"] });
    },
    onError: (error) => {
      message.error(`${error}`);
    },
  });

  const { data: models } = useQuery({
    queryKey: ["models"],
    queryFn: handleFetchModels,
  });

  const { data: sources } = useQuery({
    queryKey: ["sources"],
    queryFn: handleFetchSources,
  });

  const { data: locations } = useQuery({
    queryKey: ["locations"],
    queryFn: handleFetchLocations,
  });

  const onSubmit = async () => {
    const { mileage, vsb, ...rest } = await form.getFieldsValue();
    const data = {
      mileage: Number(mileage),
      vsb: Number(vsb) || 0,
      ...rest,
    };
    await mutate(data);
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  const year = new Date().getFullYear() + 1;

  const years: any = [];

  for (let i = 1998; i < year; i++) {
    years.unshift({ year: i });
  }

  const modelOptions = models?.map((model: any, index: number) => (
    <Option value={model?._id} key={index}>
      {model?.make + " - " + model?.name}
    </Option>
  ));

  const sourceOptions = sources?.map((source: any, index: number) => (
    <Option value={source?._id} key={index}>
      {source?.name}
    </Option>
  ));

  const locationOptions = locations?.map((location: any, index: number) => (
    <Option value={location?._id} key={index}>
      {location?.name}
    </Option>
  ));

  const yearOptions = years?.map((year: any, index: number) => (
    <Option value={year?.year} key={index}>
      {`${year?.year}`}
    </Option>
  ));

  return (
    <Modal
      open={isVisible}
      title="New Vehicle"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createVehicle"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createVehicle"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="chassis"
              label="Chassis Number"
              rules={[
                {
                  required: true,
                  message: "Please enter chassis number",
                },
              ]}
            >
              <Input type="" placeholder="Chassis Number" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="registration"
              label="Registration Number"
              rules={[
                {
                  required: false,
                  message: "Please enter Registration number",
                },
              ]}
            >
              <Input type="" placeholder="Registration Number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="vsb"
              label="VSB"
              rules={[
                {
                  required: false,
                  message: "Please enter VSB",
                },
              ]}
            >
              <Input type="" placeholder="VSB" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mileage"
              label="Mileage"
              rules={[
                {
                  required: true,
                  message: "Please enter mileage",
                },
              ]}
            >
              <Input type="number" placeholder="Mileage" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="model"
              label="Model"
              rules={[
                {
                  required: true,
                  message: "Please select model",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select model"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {modelOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="year"
              label="Year"
              rules={[
                {
                  required: true,
                  message: "Please select year",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select year"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {yearOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="color_exterior"
              label="Exterior Color"
              rules={[
                {
                  required: false,
                  message: "Please enter exterior color",
                },
              ]}
            >
              <Input type="text" placeholder="Exterior Color" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="color_interior"
              label="Interior Color"
              rules={[
                {
                  required: false,
                  message: "Please enter interior color",
                },
              ]}
            >
              <Input type="text" placeholder="Interior Color" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="source"
              label="Source"
              rules={[
                {
                  required: true,
                  message: "Please select source",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select source"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {sourceOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="location"
              label="Location"
              rules={[
                {
                  required: true,
                  message: "Please select location",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select location"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {locationOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
