import Moment from "react-moment";

export default function ReservationsDetails({ vehicle }: any) {
  return (
    <div>
      <div className="border-b border-t px-4 py-1 flex justify-between bg-gray-100">
        <div className="">Reservations</div>
      </div>
      <div className="border-t-4"></div>
      {vehicle?.status === "reserved" ? (
        <div className="mb-6 px-2">
          <div className="font-bold text-yellow-500  p-2">
            ACTIVE RESERVATION
          </div>

          <div className="px-2" style={{ fontSize: 14 }}>
            <div className="flex justify-between mb-1">
              <div className="">Customer Name: </div>
              <div className="">
                {vehicle?.reservation?.contact?.name || "-"}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="">Reservation Date : </div>
              <div className="">
                {vehicle?.reservation?.reserve_date && (
                  <Moment format="Do MMM, YYYY">
                    {vehicle?.reservation?.reserve_date}
                  </Moment>
                )}
              </div>
            </div>
          </div>
          <div className="text-yellow-500 flex px-2" style={{ fontSize: 11 }}>
            <div className="">Reserved By : </div>
            <div className="mx-1">
              {vehicle?.reservation?.consultant?.name || "-"}
            </div>
          </div>
        </div>
      ) : (
        <div className="p-4 text-xs text-center">No Active Reservation</div>
      )}

      {vehicle?.reservations?.length > 0 && (
        <div className="px-4">
          <div className="border-b pb-1">Reservation History</div>
          <div className="">
            {vehicle?.reservations?.map((item: any, index: number) => (
              <div className="py-2" style={{ fontSize: 14 }} key={index}>
                <div className="flex justify-between mb-1">
                  <div className="">Customer Name: </div>
                  <div className="">{item?.contact?.name || "-"}</div>
                </div>
                <div className="flex justify-between">
                  <div className="">Reservation Date : </div>
                  <div className="">
                    {item?.reserve_date && (
                      <Moment format="Do MMM, YYYY">
                        {item?.reserve_date}
                      </Moment>
                    )}
                  </div>
                </div>
                <div className="text-gray-500 flex" style={{ fontSize: 11 }}>
                  <div className="">Reserved By : </div>
                  <div className="mx-1">{item?.consultant?.name || "-"}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
