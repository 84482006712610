import mobility from "../../assets/images/mobility.jpg";
import BankFooter from "./BankFooter";
export default function BankDetailsThree() {
  return (
    <div className="px-16 mt-10" style={{ fontSize: 12 }}>
      <div className="rounded-3xl p-4">
        <div className="mb-8">
          <img src={mobility} alt="" width={200} />
        </div>
        <div className="" style={{ fontSize: 18, fontWeight: "bold" }}>
          <div className="flex mb-6">
            <div className="mr-6">8.</div>
            <div className="">
              <div className="">BANK OF AFRICA KENYA LIMITED</div>
              <div className="">BRANCH - SAMEEER BUSINESS PARK</div>
              <div className="">P.O BOX 69562-00400 NAIROBI</div>
              <div className="">SWIFT CODE: AFRIKENX</div>
              <div className="">BANK CODE: 19</div>
              <div className="">BRANCH CODE: 27</div>
              <div className="">KSHS A/C: 05602490003</div>
              <div className="">USD A/C: 05602490016</div>
            </div>
          </div>

          <div className="flex mb-6">
            <div className="mr-6">9.</div>
            <div className="">
              <div className="">I&M BANK LIMITED</div>
              <div className="">BRANCH - INDUSTRIAL AREA</div>
              <div className="">P.O BOX 30238-00100 NAIROBI</div>
              <div className="">SWIFT CODE: IMBLKENA</div>
              <div className="">BANK CODE: 57</div>
              <div className="">BRANCH CODE: 006</div>
              <div className="">KSHS A/C: 00603919356350</div>
              <div className="">USD A/C: 00603919351250</div>
            </div>
          </div>

          <div className="flex mb-64"> 
            <div className="mr-6">10.</div>
            <div className="">
              <div className="">STANBIC BANK KENYA LIMITED</div>
              <div className="">BRANCH - CHIROMO</div>
              <div className="">P.O BOX 30550-00100 NAIROBI</div>
              <div className="">SWIFT CODE: SBICKENX</div>
              <div className="">BANK CODE: 31000</div>
              <div className="">BRANCH CODE: 007</div>
              <div className="">KSHS A/C: 0100008846833</div>
              <div className="">USD A/C: 0100008847031</div>
            </div>
          </div>
        </div>
        <div className="">
          <BankFooter />
        </div>
      </div>
    </div>
  );
}
