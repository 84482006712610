import mobility from "../../assets/images/mobility.jpg";
import BankFooter from "./BankFooter";
export default function BankDetailsTwo() {
  return (
    <div className="px-16 mt-10" style={{ fontSize: 12 }}>
      <div className="rounded-3xl p-4">
        <div className="mb-8">
          <img src={mobility} alt="" width={200} />
        </div>
        <div className="" style={{ fontSize: 18, fontWeight: "bold" }}>
          <div className="flex mb-6">
            <div className="mr-6">4.</div>
            <div className="">
              <div className="">ABSA BANK KENYA</div>
              <div className="">SWIFT CODE: BARCKENX</div>
              <div className="">P.O BOX 46661</div>
              <div className="">NAIROBI</div>
              <div className="">BRANCH CODE: 03036</div>
              <div className="">KSHS A/C: 077-5051698</div>
              <div className="">USD A/C: 022-7318554</div>
            </div>
          </div>

          <div className="flex mb-6">
            <div className="mr-6">5.</div>
            <div className="">
              <div className="">EQUITY BANK KENYA LTD</div>
              <div className="">COMMUNITY CORPORATE BRANCH</div>
              <div className="">P.O BOX 3621 - 40100 NAIROBI</div>
              <div className="">SWIFT CODE: EQBLKENA</div>
              <div className="">BRANCH CODE: 68029</div>
              <div className="">KSHS A/C: 0290294535284</div>
            </div>
          </div>

          <div className="flex mb-6">
            <div className="mr-6">6.</div>
            <div className="">
              <div className="">CO-OPERATIVE BANK OF KENYA LIMITED</div>
              <div className="">UPPERHILL BRANCH</div>
              <div className="">SWIFT CODE: KCOOKENA</div>
              <div className="">BANK CODE: 11</div>
              <div className="">BRANCH CODE: 037</div>
              <div className="">KSHS A/C: 01192164103400</div>
            </div>
          </div>

          <div className="flex mb-56">
            <div className="mr-6">7.</div>
            <div className="">
              <div className="">NCBA BANK KENYA PLC</div>
              <div className="">BRANCH - HEAD OFFICE</div>
              <div className="">P.O BOX 44599 -00100 NAIROBI</div>
              <div className="">BANK CODE: 07</div>
              <div className="">BRANCH CODE: 000</div>
              <div className="">KSHS A/C: 4843630016</div>
              <div className="">USD A/C: 4843630021</div>
              <div className="">EUR A/C: 4843630063</div>
            </div>
          </div>
        </div>
        <div className="">
          <BankFooter />
        </div>
      </div>
    </div>
  );
}
