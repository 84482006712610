export default function DocumentCustomer({ proforma }: any) {

  return (
    <div style={{fontSize: 11}} className="flex border border-black">
      <div className="w-1/2 border-r border-black p-2">
        <div className="mb-1">
          <span className="font-bold underline">Customer Account No:</span>{" "}
          <span>{proforma?.contact?.customerId}</span>
        </div>

        <div className="mb-4">
          <div className="font-bold underline">Delivery Address:</div>
          <div className="">{proforma?.contact?.name}</div>
          <div className="">P.O BOX {proforma?.contact?.postalCode || "-"}</div>
          <div className="">{proforma?.contact?.city || "-"}</div>
          <div className="">{proforma?.contact?.country || "-"}</div>
        </div>

        <div className="mb-2">
          <div className="">
            <span className="font-bold underline mr-2">Tel No:</span>
            <span>{proforma?.contact?.mobile}</span>
          </div>
          <div className="font-bold underline">Contact Name:</div>
          <div className="">
            <span className="font-bold">Email: </span>
            <span className="font-bold">{proforma?.contact?.email}</span>
          </div>
          <div className="">
            <span className="font-bold">PIN No: </span>
            <span className="font-bold">{proforma?.contact?.pin || "-"}</span>
          </div>
          <div className="">
            <span className="font-bold">Customer VAT Registration No: </span>
            <span className="font-bold">{proforma?.contact?.vrn || "-"}</span>
          </div>
        </div>
      </div>
      <div className="w-1/2 p-2">
        <div className="mb-6">
          <div className="font-bold underline">Payer Address:</div>
          <div className="">{proforma?.contact?.name}</div>
        </div>
        <div className="mb-6">
          <div className="">{proforma?.contact?.gpo || "-"}</div>
          <div className="">{proforma?.contact?.country || "-"}</div>
        </div>
        <div className="">
        <div className=""><span className="font-bold mr-2">Tel No:</span><span>{proforma?.contact?.mobile}</span></div>
        <div className=""><span className="font-bold mr-2">Email:</span><span>{proforma?.contact?.email}</span></div>
        </div>
      </div>
    </div>
  );
}
